import React from "react";
import { graphql } from "gatsby";
// import Img from "gatsby-image";

import styled from "styled-components";
import { Container } from "react-bootstrap";
import Layout from "../components/layout";
import SEO from "../components/seo";
import * as style from "./about.module.css";

const PageHeader = styled.h1`
  text-align: center;
`;
const ImpressumPage = ({ data }) => {
  return (
    <Layout>
      <SEO
        title="Impressum"
        description="legal inforamtion about Art in Common"
        image="/logo.png"
        pathname="/impressum"
        // Boolean indicating whether this is an article:
        // article
      />
      <section className={style.wrapper}>
        <PageHeader className={style.heading}>
          <div>Impressum</div>
        </PageHeader>
        <Container>
          <div className={style.entertext}>
            <p>
              Julia-Lena Lippoldt
              <p>Schirmerstr. 46-48 50823 Köln</p>
              jule.lippoldt@gmx.de
            </p>
          </div>
        </Container>
      </section>
    </Layout>
  );
};

export default ImpressumPage;

export const query = graphql`
  {
    headerImage: file(
      relativePath: { eq: "robots-androids-and-cyborgs-oh-my-1184x360.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1184) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    robotImage: file(relativePath: { eq: "bubbles-disc.png" }) {
      childImageSharp {
        fixed(width: 300) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;
